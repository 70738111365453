import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Adad`}</strong>{` or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`العدد`}</strong>{` is a very fascinating concept of Arabic grammar in which we learn how Arabs used to count things.`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Disclaimer`}</strong>{` Arabic counting gets very tricky. So have patience and do a lot of Istigfaar in the meantime :)`}</p>
    <br />
    <p>{`Lets divide the numbers into 4 cases and begin with `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 1`}</strong>{` `}<strong parentName="p">{`العدد of 1 and 2`}</strong>{`  `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`المفرد`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`اشتريت كتاباً`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`المثنى`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اشترت كتابين`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Where كتاباً itself means a single book and كتابين means two books. So we don't need any extra word to describe its count.`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 2`}</strong>{` `}<strong parentName="p">{`العدد from 3 to 10`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Rule`}</em>{` It is plural and Majroor (i.e. تمييزه جمع مجرور) with opposite Gender (i.e. العدد يخالف المعدود)`}<br parentName="p"></br>{`
`}{`Example: `}</p>
    <ul>
      <li parentName="ul">{`اشتريت ثلاثة كتبٍ`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`كتبٍ`}</strong>{`
مضاف إليه مجرور وعلامة جره الكسرة`}<br parentName="p"></br>{`
`}{`OR`}<br parentName="p"></br>{`
`}{`تمييز مجرور وعلامة جره الكسرة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <p>{`where ثلاثة is عدد and كتب is معدود`}</p>
    <br />
    <p>{`Also اشتريت ثلاثة كتب and اشتريت ثلاثة `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`من`}</strong>{` الكتبِ means same thing. We can use both formats.`}</p>
    <br />
    <p>{`More examples:`}</p>
    <ul>
      <li parentName="ul">{`رأيت ثلاثةَ رجال`}</li>
      <li parentName="ul">{`رأيت ثلاثَ نساء`}</li>
      <li parentName="ul">{`رأيت عشرة رجال`}</li>
      <li parentName="ul">{`رأيت عشر نساء`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 3`}</strong>{` `}<strong parentName="p">{`العدد from 11 to 99`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Rule`}</em>{` It is Singular and Mansoob  (i.e. تمييزه مفرد منصوب)`}<br parentName="p"></br>{`
`}{`Example: اشتريت أحد عشر قلماً`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`قلماً`}</strong>{`
تمييز منصوب وعلامة نصبه الفتحة`}</p>
    </blockquote>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 3a`}</strong>{` `}<strong parentName="p">{`Gender from 11 to 19 is يوافق المعدود`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`جاء ثلاث`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ةَ`}</strong>{` عشرَ رجلاً`}
        <ul parentName="li">
          <li parentName="ul">{`Gender of عشرَ is same as رجلاً and ثلاثةَ is opposite of رجلاً`}</li>
        </ul>
      </li>
      <li parentName="ul">{`رأيت ثلاث عشر`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ة`}</strong>{` امرأ`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ة`}</strong></li>
      <li parentName="ul">{`رأيت أحد عشر رجلاً`}</li>
    </ul>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 3b`}</strong>{` `}<strong parentName="p">{`Gender from 20 to 99 doesn't change (لا تتغير)`}</strong></p>
    <ul>
      <li parentName="ul">{`رأيت ثلاثاً وعشرين امرأةً`}</li>
      <li parentName="ul">{`رأيت ثلاثةَ وعشرين رجلاً`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 4`}</strong>{` `}<strong parentName="p">{`العدد from 100 to 1000`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Rule`}</em>{` It is Singular and Majroor (i.e. تمييزه مفرد مجرور) and doesn't change (لا تتغير)`}<br parentName="p"></br>{`
`}{`Example: اشتريت مائة كتابٍ`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`كتبٍ`}</strong>{`
مضاف إليه مجرور وعلامة جره الكسرة`}<br parentName="p"></br>{`
`}{`OR`}<br parentName="p"></br>{`
`}{`تمييز مجرور وعلامة جره الكسرة  `}</p>
    </blockquote>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.37423312883436%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA9hAAAPYQGoP6dpAAABXUlEQVQoz41SiU7CQBDd//8gMeEQ8EajIRANCcECERBKt1dou+3uc7q1uFzKJC9zvZnO7JTBEKUUobATISEyICU/1zH5mVQGT+GYMJNQknJt2RK3vTnqnSHu+3P0pzG8jcQp/rahmdSJH/BQYrwIMJysYc08LLhAJH4bYq+uBMt7q521AZmvpuTuLlJSSOlcDrPOtFk5USZSiDgBPRRUlumYiAVkmpVfgSSO9vOmZCuyi7zcTsyc7itmrSbeKxcYXFbw2W7Cpdjq8QEDik2aV/B7XSzvbjCqVWE1aphftxH0e7CJYzXqGBP4yzOUEGAiCBBxB8LzIHwPkUM2xRLPxcZeI+YcaRhoHbsuEkLOScNQc2KXUz2nWl9PyXBC1N7bnCPq+JWN7ME/+j+YWaCPmXB8fbSwmnZorTGstyr47IkIaTGx+nvmw4bpBp49gu9MICIHznKI0J3qS57T8Bvz51dd6KfLBgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Four Cases of Adad",
          "title": "Four Cases of Adad",
          "src": "/static/8fabf172c8458e03ebfaf0161b0521e1/a6d36/4-cases-of-adad.png",
          "srcSet": ["/static/8fabf172c8458e03ebfaf0161b0521e1/222b7/4-cases-of-adad.png 163w", "/static/8fabf172c8458e03ebfaf0161b0521e1/ff46a/4-cases-of-adad.png 325w", "/static/8fabf172c8458e03ebfaf0161b0521e1/a6d36/4-cases-of-adad.png 650w", "/static/8fabf172c8458e03ebfaf0161b0521e1/e548f/4-cases-of-adad.png 975w", "/static/8fabf172c8458e03ebfaf0161b0521e1/e996b/4-cases-of-adad.png 1050w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <h2>{`Points to Remember`}</h2>
    <ul>
      <li parentName="ul">{`Look at singular المعدود whether it is masculine or feminine not its plural form i.e. (ننظر إلى مفرد المعدود هل هو مذكر أو مؤنث ولا ننظر إلى الجمع)`}
        <ul parentName="li">
          <li parentName="ul">{`Example in  سخرها عليهم سبع `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`ليال`}</strong>{` وثمانية `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيام`}</strong>{` حسوماً we will consider ليلة (feminine) and يوم (masculine) `}<strong parentName="li">{`NOT`}</strong>{` ليال (masculine) and أيام (feminine)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`1985`}
        <ul parentName="li">
          <li parentName="ul">{`5 = آحاد`}</li>
          <li parentName="ul">{`8 =  عشرات`}</li>
          <li parentName="ul">{`9 = مئات`}</li>
          <li parentName="ul">{`1 = آلاف`}</li>
        </ul>
      </li>
      <li parentName="ul">{`1440`}
        <ul parentName="li">
          <li parentName="ul">{`Right to Left: سنة ألف و أربعمائة وأربعين`}</li>
          <li parentName="ul">{`Left to Right: سنة أربعين وأربعمائة و ألف`}</li>
        </ul>
      </li>
    </ul>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`العدد المركب`}</strong></h2>
    <p>{`It is from 11 to 19 (أحد عشر  - تسع عشر)`}<br parentName="p"></br>{`
`}{`Both the parts are Mabni (مبني على فتح الجزئين) i.e. أحد and عشر both mabni.`}<br parentName="p"></br>{`
`}{`For Example:`}</p>
    <ul>
      <li parentName="ul">{`جاء أحدَ عشرَ رجلاً`}
        <ul parentName="li">
          <li parentName="ul">{`أحدَ عشرَ is Fai'l `}</li>
        </ul>
      </li>
      <li parentName="ul">{`رأيت أحدَ عشرَ رجلاً`}
        <ul parentName="li">
          <li parentName="ul">{`أحدَ عشرَ is Mafool Bihi`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ذهبت إلى أحدَ عشرَ رجلاً`}</li>
    </ul>
    <br />
    <p>{`Irab of جاء أحدَ عشرَ رجلاً`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أحدَ عشرَ`}</strong>{`
عدد مبني على فتح الجزئين في محل رفع فاعل`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception`}</strong>{` with 12 i.e. اثنا عشر where اثنا is Mo'rab and عشر is Mabni (الجزء الأول معرب والجزء الثاني مبني على الفتح)`}</p>
    <ul>
      <li parentName="ul">{`جاء اثنا عشرَ رجلاً`}</li>
      <li parentName="ul">{`رأيت اثني عشرَ رجلاً`}</li>
      <li parentName="ul">{`ذهبت إلى اثني عشرَ رجلاً`}</li>
    </ul>
    <br />
    <p>{`Irab of جاء اثنا عشرَ رجلاً`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`اثنا عشر`}</strong>{`
اثنا فاعل مرفوع وعلامة رفعه الألف نيابة عن الضمة
عشر مبني على الفتح`}</p>
    </blockquote>
    <br />
    <p>{`That's it folks !!!`}<br parentName="p"></br>{`
`}{`That's how Adad in Arabic works`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      